import React, {useEffect, useState} from "react";
import logo from "../images/immobili.jpg";
import "../App.css";
import Navbar from "../components/navbar/Navbar";
import "./immobili.css"

function Immobili() {
    const [json, setJson] = useState<any[]>([])
    /*
    React.useEffect(() => {
        fetch("/api")
            .then((res) => res.json())
            .then((data) => setJson(data.message))
    }, []);
    */

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    // @ts-ignore
    return (
        <div className="App">
            <Navbar></Navbar>
            <div className={matches ? "container" : "containerMin"}>
                <img src={logo} className={matches ? "immobiliimage" : "immobiliimageMin"} />
                <div className="centered"><h1>IMMOBILI</h1></div>
            </div>
            <div className="content">
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    <a href={"https://www.immobiliare.it/annunci/114526345/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1574076893/cover-m-c.jpg"}/>
                            <a><h4>{"Via Belvedere 7, Ponticella"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 545000"}</p></a>
                        </div>
                    </a>              
                    <a href={"https://www.immobiliare.it/annunci/116583917/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1615890129/cover-m-c.jpg"}/>
                            <a><h4>{"Bilocale via Casarini 32"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 300000"}</p></a>
                        </div>
                    </a>
                    <a href={"https://www.immobiliare.it/annunci/114903479/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1582294575/cover-m-c.jpg"}/>
                            <a><h4>{"Via del Lido, Casalecchio"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 260000"}</p></a>
                        </div>
                    </a>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    <a href={"https://www.immobiliare.it/annunci/91657974/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                        <div className="containerImm"><div className="venduto">VENDUTO</div>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1127665060/m-c.jpg"}/>
                            </div>
                            <a><h4>{"Via 25 Aprile 1945 3, San Lazzaro"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 1150000"}</p></a>
                        </div>
                    </a>
                             
                    <a href={"https://www.immobiliare.it/annunci/115344905/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1590659229/cover-m-c.jpg"}/>
                            <a><h4>{"Quadrilocale via Cristoni, Casalecchio"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 330000"}</p></a>
                        </div>
                    </a>
                    
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                {/* 
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    <a href={"https://www.immobiliare.it/annunci/91657974/"}>
                        <div className="container"><div className="venduto">VENDUTO</div>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1127665060/m-c.jpg"}/>
                            <a><h4>{"Via 25 Aprile 1945 3, San Lazzaro"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 1150000"}</p></a>
                        </div>
                        </div>
                    </a>
                    
                    <a href={"https://www.immobiliare.it/annunci/107343105/"}>
                        <div className="container"><div className="venduto">VENDUTO</div>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1433564633/cover-m-c.jpg"}/>
                            <a><h4>{"Appartamento via Spartaco"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 390000"}</p></a>
                        </div>
                        </div>
                    </a>
                   
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                */}
                {/* 1 immobile solo nella riga 
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    <a href={"https://www.immobiliare.it/annunci/91657974/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                        <div className="container"><div className="venduto">VENDUTO</div>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1127665060/m-c.jpg"}/>
                            </div>
                            <a><h4>{"Via 25 Aprile 1945 3, San Lazzaro"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 1150000"}</p></a>
                        </div>
                    </a>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                */}
                {/*
                <div className={matches ? "contactsContainer" : "contactsContainerMin"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>

                    <a href={"https://www.immobiliare.it/annunci/91657974/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1127665060/m-c.jpg"}/>
                            <a><h4>{"Via 25 Aprile 1945 3, San Lazzaro"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: su richiesta"}</p></a>
                        </div>
                    </a>
                    <a href={"https://www.immobiliare.it/annunci/96593710/"}>
                        <div className={matches ? "contactsBoxImm" : "contactsBoxImmMin"}>
                            <img className={"houseImage"} src={"https://pwm.im-cdn.it/image/1192812134/cover-m-c.jpg"}/>
                            <a><h4>{"Rifugio via Pozza 2, Monterenzio"}</h4></a>
                            <a><p>{"Vendita"}</p></a>
                            <a><p>{"Prezzo: € 99000"}</p></a>
                        </div>
                    </a>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                */}


                <div className={"contactsContainer"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    {json && json.map((item, index) =>
                        {return index < 3 ?
                            <a href={item.link}>
                                <div className={"contactsBoxImm"}>
                                    <img className={"houseImage"} src={item.image}/>
                                    <h4>{item.via}</h4>
                                    <a><p>{item.tipo}</p></a>
                                </div>
                            </a> :
                            <div></div>
                        })
                    }
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
                <div className={"contactsContainer"}>
                    <div className={"contactsBoxEmpty"}>
                    </div>
                    {json && json.map((item, index) =>
                    {return index >= 3 ?
                        <a href={item.link}>
                            <div className={"contactsBoxImm"}>
                                <img className={"houseImage"} src={item.image}/>
                                <h4>{item.via}</h4>
                                <a><p>{item.tipo}</p></a>
                            </div>
                        </a> :
                        <div></div>
                    })
                    }
                    <div className={"contactsBoxEmpty"}>
                    </div>
                </div>
            </div>
            <footer className={matches ? "footerImm" : "footerImmMin"}>
                <p>© Lorena Ceccacci immobiliare, All Rights Reserved</p>
            </footer>
        </div>
    );
}

export default Immobili;

